import React from "react";

export default function ErrorNotice(props) {
  return (
    <div  className="error-notice">
      <span role="alert" aria-live="polite" style={{color: 'white'}}>{props.message}</span>
      <button aria-label="Close" onClick={props.clearError}>X</button>
    </div>
  );
}
