import React, { useState, useMemo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Button,
  Flex
} from "@chakra-ui/react";
import "./style.css";
import ErrorDetail from "../ErrorDetail";
import Congratulations from "../../assets/congratulation.svg";
import modifiedErrorInfoList from "../../utils/errorInfoData";
import Axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import urlActions from "../../store/actions/urlActions";
import { Progress } from '@chakra-ui/react'
import Alert from '@material-ui/lab/Alert';
import userActions from "../../store/actions/userActions";

const UrlDetail = ({ count, url, scanComplete, setScanComplete, selectedBadge }) => {

  const [isScanning, setIsScanning] = useState(false);
  const dispatch = useDispatch();

  const scanAgain = async () => {
    setIsScanning(true)
    const currentTime = moment().format("MM/DD/YYYY, h:mm:ss a");
    
    const scanResponse = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/scanTargetUrl", {
      url: url.mainUrl,
      targetUrl: url.name,
      currentTime,
      numberOfUrls: 1,
    }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
      return response.data
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch(userActions.logOut())
      }
      return { "pageTitle": "" }
    });

    dispatch(urlActions.addNewScan(url.name, scanResponse.pageTitle, currentTime, scanResponse.scanId));
    setIsScanning(false)
    setScanComplete(true)
  }

  const calculatedCount = useMemo(() => {
    let errorCount = 0;
    const errorList = url.errorList || {};

    if (selectedBadge === "new_errors") {
      errorCount = Object.keys(errorList).reduce((count, errorName) => (
        modifiedErrorInfoList[errorName] ? count + 1 : count
      ), 0);
    } else {
      errorCount = Object.keys(errorList).reduce((count, errorName) => {
        const error = errorList[errorName];
        if (!error.category || selectedBadge === "total_errors" || selectedBadge === `${error.category}_errors`) {
          return modifiedErrorInfoList[errorName] ? count + 1 : count;
        }
        return count;
      }, 0);
    }

    return errorCount;
  }, [url, selectedBadge]);

  const variant = useMemo(() => {
    if (calculatedCount >= 10) return "pink";
    if (calculatedCount > 0) return "yellow";
    return "green";
  }, [calculatedCount]);

  function listErr() {
    if (selectedBadge === "new_errors") {
      return Object.keys(url.errorList).map(errorName => {
        if (modifiedErrorInfoList[errorName]) {
          return (
            <ErrorDetail
              name={errorName}
              id={url._id}
              key={url._id + url.time + errorName}
              count={url.errorList[errorName].count}
              domId={url.domid}
            />
          );
        }
        return null;
      });
    }
    
    return Object.keys(url.errorList).map(errorName => {
      if (
        !url.errorList[errorName].category ||
        selectedBadge === "total_errors" ||
        selectedBadge === url.errorList[errorName].category + "_errors"
      ) {
        if (modifiedErrorInfoList[errorName]) {
          return (
            <ErrorDetail
              name={errorName}
              id={url._id}
              key={url._id + url.time + errorName}
              count={url.errorList[errorName].count}
              domId={url.domid}
            />
          );
        }
      }
      return null;
    });
  }

  return (
    <div className="accordion">
      {isScanning ? (
        <Progress className="progress_bar" size='xs' isIndeterminate></Progress>
      ) : null}
      {scanComplete ? (
        <Alert onClose={() => setScanComplete(false)}>Your scan was ran successfully!</Alert>
      ) : null}
      <Accordion allowToggle>
        <AccordionItem
          className={`url-title__container--${variant}`}
          isFocusable={false}
        >
          <Flex alignItems="center">
            <AccordionButton>
              <div className="url-title__container ">
                <div className="url-title__sub-container">
                  <span
                    className={`accordion__count accordion__count--${variant} `}
                  >
                    {calculatedCount}
                  </span>
                  <h3 className="accordion__title">{url.name}</h3>
                </div>
                <div className="accordion__icon-container">
                  <AccordionIcon color="#1f3366" width="26" height="26" />
                </div>
              </div>
            </AccordionButton>
            <Button onClick={scanAgain} backgroundColor="transparent" className="scan_title">Scan Again</Button>
          </Flex>
          <AccordionPanel p={8} className="sub-panel">
            <Accordion allowToggle>
              {count ? listErr() : (
                <div className="accordion_congratulation">
                  <img src={Congratulations} alt="congratulation" />
                  <div className="congratulation__container">
                    <h1 className="congratulation__heading">
                      Congratulations!
                    </h1>
                    <h1 className="congratulation__sub-heading">
                      No critical errors found for this url
                    </h1>
                  </div>
                </div>
              )}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UrlDetail;
