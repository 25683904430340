import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";
import DashboardMain from "./components/pages/DashboardMain";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/pages/forgotPassword";
import ResetPassword from "./components/pages/resetPassword";
import AuthRoute from "./components/auth/AuthRoute";
import AdminRoute from "./components/auth/AdminRoute";
import SupportTicket from "./components/pages/supportTicket";
import UserContext from "./context/UserContext";
import ScanContext from "./context/ScanContext";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import userActions from "./store/actions/userActions";
import urlActions from "./store/actions/urlActions";
import Users from "./components/auth/Users";
import ScheduledScans from "./components/auth/ScheduledScans";

export default function App() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });

  const [loadingData, setLoadingData] = useState(false);
  const [totalErrors, setTotalErrors] = useState(0);
  const [scanData, setScanData] = useState({
    url: undefined,
    complianceScore: undefined,
  });
  const [scanUrls, setScanUrls] = useState([]);
  const [scanProgress, setScanProgress] = useState(0);
  const [loadingPage, setLoadingPage] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [globalLinks, setGlobalLinks] = useState([]);
  
  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === "null") {
        setRedirect(true);
        return;
      }

      const tokenRes = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/tokenIsValid",
        { headers: { Authorization: token } }
      );

      if (tokenRes.data) {
        setUserData({ token: tokenRes.data.token, user: tokenRes.data.user });
        setLoadingPage(false);
      } else {
        setRedirect(true);
      }
    };
    if (userData.user) {
      setLoadingPage(false);
    } else {
      checkLoggedIn();
    }

    dispatch(userActions.checkAuth());
  }, [dispatch, userData.user]);

  let element = document.createElement("span");
  element.style.position = "absolute";
  element.style.left = "-99999px";
  for (
    let i = 0;
    i < document.getElementsByClassName("MuiButtonBase-root").length;
    i++
  ) {
    document
      .getElementsByClassName("MuiButtonBase-root")
    [i].appendChild(element);
  }
  for (
    let i = 0;
    i < document.getElementsByClassName("MuiIconButton-root").length;
    i++
  ) {
    document
      .getElementsByClassName("MuiIconButton-root")
    [i].appendChild(element);
  }
  let elem = document.getElementsByClassName("MuiIconButton-root");
  for (let i = 0; i < elem.length; i++) {
    elem[i].ariaLabel = "MuiIconButton-root";
  }
  async function scanUrl(url) {
    if (url) {
      try {
        setLoadingData(true);
        const response = await Axios.post(
          process.env.REACT_APP_BACKEND_URL + "/scans/scanUrl",
          { url },
          { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
        );
        setScanUrls(response.data.urlList);
        var currentTime = moment().format("MM/DD/YYYY, h:mm:ss a");

        var scanResponse = {"pageTitle": ""}
        for (let i = 0; i < response.data.urlList.length; i++) {
          scanResponse = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/scanTargetUrl", {
            url,
            targetUrl: response.data.urlList[i],
            currentTime,
            numberOfUrls: response.data.urlList.length,
          }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
            return response.data
          }).catch(function (error) {
            if (error.response.status === 401) {
              dispatch(userActions.logOut())
            }
            return {"pageTitle": ""}
          });
          setScanProgress((currentProgress) => currentProgress + 100 / response.data.urlList.length);
        }
        dispatch(urlActions.addNewScan(url, scanResponse.pageTitle, currentTime, scanResponse.scanId));
      } catch (err) {

      } finally {
        setLoadingData(false);
        setScanProgress(0);
      }
    }
  }
  return (
    <>
      <BrowserRouter>
        <UserContext.Provider
          value={{
            userData,
            setUserData,
            loadingData,
            scanUrl,
            scanProgress,
            loadingPage,
            setLoadingPage,
            redirect,
            setRedirect,
            globalLinks,
            setGlobalLinks,
            totalErrors,
            setTotalErrors
          }}
        >
          <ScanContext.Provider value={{ scanData, setScanData }}>
            <AuthRoute exact path="/" component={DashboardMain}></AuthRoute>
            <Route path="/login" component={Login} />
            <AuthRoute path="/support" component={SupportTicket} />
            <AdminRoute path="/register" component={Register} />
            <AdminRoute path="/user" component={Users} />
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset/:id" component={ResetPassword} />
            <AdminRoute path="/scheduled-scans" component={ScheduledScans} />
            <div className="container">
              <Switch></Switch>
            </div>
          </ScanContext.Provider>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}
