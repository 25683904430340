import React, { useContext, useState, useEffect } from "react";
import Badge from "../Badge";
import "./style.css";
import urls from "../../assets/urls.svg";
import contrastErrors from "../../assets/contrastErrors.svg";
import criticalErrors from "../../assets/criticalErrors.svg";
import newErrors from "../../assets/newErrors.svg";
import "react-circular-progressbar/dist/styles.css";
import UserContext from "../../context/UserContext";
import { useSelector } from "react-redux";
import modifiedErrorInfoList from "../../utils/errorInfoData";

const ScanResult = (props) => {
  const { totalErrors } = useContext(UserContext);
  const [newError, setNewError] = useState(null);
  const { selectedUrl, selectedTime, scoreCardsData, new_errors, loading } = useSelector(
    (state) => state.urls
  );
  const [loadingBadge, setLoading] = useState({
    totalErrors: false,
    newErrors: false,
    contrastErrors: false,
    criticalErrors: false
  });

  useEffect(() => {
      setLoading({
        totalErrors: true,
        newErrors: true,
        contrastErrors: true,
        criticalErrors: true
      });

      if (!loading) {
        setLoading({
          totalErrors: false,
          newErrors: false,
          contrastErrors: false,
          criticalErrors: false
        });
      }
  }, [selectedUrl, selectedTime, loading]);
  
  useEffect(() => {
    let totalNewErrors = 0;
    if (new_errors?.length > 0) {
        new_errors.forEach(error => {
            Object.keys(error.errorList).forEach(errorName => {
                if (typeof modifiedErrorInfoList[errorName] !== 'undefined') {
                  totalNewErrors += error.errorList[errorName].count
                }
            });
        });
    }
    setNewError(totalNewErrors);
}, [new_errors]);

  let element = document.createElement('span');
  element.style.position = 'absolute';
  element.style.left = '-99999px';
  for(let i = 0; i< document.getElementsByClassName("MuiButtonBase-root").length; i++){
    document.getElementsByClassName("MuiButtonBase-root")[i].appendChild(element);
  }
  for(let i = 0; i< document.getElementsByClassName("MuiIconButton-root").length; i++){
    document.getElementsByClassName("MuiIconButton-root")[i].appendChild(element);
  }

  return (
    <div>
      <h2 className="metrics__heading">Scan Results</h2>
      <p className="mb-2">Result for {props.totalPage.length} pages scanned. Use the buttons below to filter your findings</p>
      <div className="badge-list" role="list">
        <Badge
          variant="pink"
          selected={props.selectedBadge === "total_errors"}
          title="Total Errors"
          count={totalErrors}
          icon={urls}
          clickHandler={() => props.setSelectedBadge("total_errors")}
          role="listitem"
          loading={loadingBadge.totalErrors}
        />
        <Badge
          variant="gray"
          selected={props.selectedBadge === "new_errors"}
          title="New Errors"
          count={newError}
          icon={newErrors}
          clickHandler={newError > 0 ? () => props.setSelectedBadge("new_errors") : undefined}
          role="listitem"
          loading={loadingBadge.newErrors}
        />
        <Badge
          variant="green"
          selected={props.selectedBadge === "contrast_errors"}
          title="Contrast Errors"
          count={scoreCardsData && scoreCardsData.contrastErrorCount > 0 ? scoreCardsData.contrastErrorCount : 0 }
          icon={contrastErrors}
          clickHandler={() => props.setSelectedBadge("contrast_errors")}
          role="listitem"
          loading={loadingBadge.contrastErrors}
        />
        <Badge
          variant="pink"
          selected={props.selectedBadge === "error_errors"}
          title="Critical Errors"
          clickHandler={() => props.setSelectedBadge("error_errors")}
          count={scoreCardsData && scoreCardsData.errorErrorCount > 0 ? scoreCardsData.errorErrorCount : 0 }
          icon={criticalErrors}
          role="listitem"
          loading={loadingBadge.criticalErrors}
        />
      </div>
    </div>
  );
};

export default ScanResult;
