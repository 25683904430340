import React, { useState, useMemo, useEffect, Fragment } from "react";
import {
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
} from "@chakra-ui/react";
import modifiedErrorInfoList from "../../utils/errorInfoData";
import axios from "axios";
import { Progress } from '@chakra-ui/react'
import ReactPrism from '@versant-digital/react-prismjs';
import Highlight from 'react-highlighter';

import "./errordetail.css";
import userActions from "../../store/actions/userActions";
import { useDispatch } from "react-redux";

const ErrorDetail = ({ name, count, id }) => {
  const [selected, setSelected] = useState(true);
  const [selectedError, setSelectedError] = useState({});

  const info = useMemo(() => {
    return modifiedErrorInfoList[name];
  }, [name]);

  let [imageError, setImageError] = useState(false);
  let [codeError, setCodeError] = useState(false);

  let [image, setImage] = useState("");
  let [searchImage, setSearchImage] = useState(false);
  let [loadingCode, setLoadingCode] = useState(false);
  let [loadingImage, setLoadingImage] = useState(false);
  let [codeSnippet, setCodeSnippet] = useState(false);
  let [rawHTML, setRawHTML] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getErrorScreenCap(params) {
      setImageError(false);
      try {
        setLoadingImage(true)
        setLoadingCode(true);

        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/scans/errorDetail",
          { errorType: name, id: id },
          { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
        ).catch(function (error) {
          if (error.response.status === 401) {
            dispatch(userActions.logOut())
          }
        });

        setImage(process.env.REACT_APP_BACKEND_URL + "/" + response.data.Image);
        setLoadingImage(false)
        setLoadingCode(false)
        setCodeSnippet(response.data.element)
        setRawHTML(response.data.rawHTML)
      } catch (error) {
        setLoadingImage(false)
        setImageError(true)

        setLoadingCode(false);
        setCodeError(true);
      }
    }
    if (selected) {
      if (searchImage && !image) {
        getErrorScreenCap();
      }
    }
  }, [searchImage, selected]);

  if (!info) {
    return null;
  }
  return (
    <AccordionItem>
      <div onClick={() => { setSelectedError({ [name]: true }); setSearchImage(true) }}>
        <AccordionButton>
          <div className="url-title__container ">
            <div className="url-title__sub-container">
              <span className="accordion__count accordion__count--sub">
                {count}
              </span>
              <h4 className="accordion__title accordion__title-sub">
                {name}
              </h4>
            </div>
            <AccordionIcon color="#1f3366" width="26" height="26" />
          </div>
        </AccordionButton>
      </div>
      <AccordionPanel pb={4}>
        <div className="accordion__error-detail-container">
          <div className="accordion__error-detail">
            <h5 className="accordion__error-heading ">Initial Scan Errors</h5>
            <p className="accordion__error-paragraph">
              {info ? info.title : ""}
            </p>
            <h5 className="accordion__error-heading ">What It Means</h5>
            <p className="accordion__error-paragraph">
              {info ? info.summary : ""}
            </p>
            <h5 className="accordion__error-heading ">Why It Matters</h5>
            <p className="accordion__error-paragraph">
              {info ? info.whyItMatters : ""}
            </p>
            <h5 className="accordion__error-heading ">In English</h5>
            <p className="accordion__error-paragraph">
              {info ? info.inEnglish : ""}
            </p>
            <h5 className="accordion__error-heading ">How To Fix It</h5>
            <p className="accordion__error-paragraph">
              {info ? info.howToFixIt : ""}
            </p>

            <div className="info__box">
            </div>
          </div>
          <div className="accordion__error-detail-subcontainer">

            <div className="accordion__button-container " >
              <button aria-hidden={selected} aria-pressed={!selected} aria-label={!selected && "HTML Selected"}
                onClick={() => setSelected(false)}
                className={`accordion__button ${!selected && "accordion__button--selected"}`}
              >
                HTML
              </button>
              <button aria-hidden={!selected} aria-pressed={selected} aria-label={selected && "Preview Selected"}
                onClick={() => setSelected(true)}
                className={`accordion__button ${selected && "accordion__button--selected"}`}
              >
                Preview{" "}
              </button>
            </div>

            <div className="accordion__error-detail-subcontainer-image ">
              {
                selected ?
                  loadingImage ? (
                    <div>
                      <div className="accordion_congratulation">
                        <div className="congratulation__container">
                          <p className="congratulation__heading">
                            Loading Your Screenshot!
                          </p>
                        </div>
                      </div>
                      <Progress size="xs" isIndeterminate></Progress>
                    </div>
                  ) : imageError ? (
                    <div className="accordion_congratulation">
                      <div className="congratulation__container">
                        <p className="congratulation__heading">
                          All According to plan!
                        </p>
                        <p className="congratulation__sub-heading">
                          No highlightable html element found for this error.
                        </p>
                      </div>
                    </div>
                  ) : <img alt="Screen shot of highlighted accessibility errors" src={image} /> : loadingCode ?
                    <div>
                      <div className="accordion_congratulation">
                        <div className="congratulation__container">
                          <p className="congratulation__heading">
                            Loading Your Code!
                          </p>
                        </div>
                      </div>
                      <Progress size="xs" isIndeterminate></Progress>
                    </div> : codeError ? (
                      <div className="accordion_congratulation">
                        <div className="congratulation__container">
                          <p className="congratulation__heading">
                            All According to plan!
                          </p>
                          <p className="congratulation__sub-heading">
                            No highlightable html element found for this error.
                          </p>
                        </div>
                      </div>
                    ) : (
                      codeSnippet !== false ? (
                        codeSnippet.length === 1 ?
                          codeSnippet.map(code => <ReactPrism key="0" language="html" ><Highlight search>{code}</Highlight></ReactPrism>)
                        :
                          codeSnippet.map((code, k) => <Fragment><div className="err-instance-heading">Instance {k + 1}: </div><ReactPrism key={k} language="html" ><Highlight search={codeError}>{code}</Highlight></ReactPrism></Fragment>)
                      ) : ''
                    )
              }
            </div>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ErrorDetail;
