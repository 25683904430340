import React, { useMemo } from "react";
import "./style.css";
import Spinner from '../Spinner'; 

const Badge = ({ title, count, selected, clickHandler, icon, role, loading }) => {
  const variant = useMemo(() => {
    if(count >= 5 ) {
      return 'pink'
    }
    if (count === 0 && title === 'New Errors') {
      return 'gray'
    }
    if(count > 0) {
      return 'yellow'
    }
    return 'green'
  }, [count, title])

  return (
    <button
      className={`badge badge--${variant} ${
        selected && `badge--${variant}-selected`
      } ${selected && `selected`}`}
      onClick={clickHandler}
      aria-controls={title}
      aria-pressed={selected}
      role={role}
      disabled={loading} 
    >
      <div className="badge__detail">
      <img  className="badge__icon" src={icon} alt="" />
      </div>
      <h3 className="badge__heading">{title}</h3>
      <p className={`badge__count ${!selected && `badge__color--${variant}`}`}>
      {loading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          count
        )}
      </p>
    </button>
  );
};

export default Badge;
