function showDate(dateStr) {
  const date = new Date(dateStr);
  return date.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
  });
}

function selectOption(name) {
  if (!name || typeof name !== 'string' || name.length === 0) {
      return '';
  } else if (name.length > 20) {
      return ' - ' + name.slice(0, 18) + '...';
  }
  return ' - ' + name;
}

export function renderOption(data) {
  let options = [];
  data.forEach(item => {
      const text = showDate(item.time) + selectOption(item.pageTitle);
      options.push(
          <option value={item.time} key={item.scanId} data-scanid={item.scanId}>
              {text}
          </option>
      );
  });
  return options;
}