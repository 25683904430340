import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';
import adascloginImage from './loginDashboard.png'
import ErrorPage from './ErrorPage';
import ErrorNotice from "../misc/ErrorNotice";
import { reportWebVitals as sendGA } from '../../reportWebVitals';
import PasswordResetEvent from '../../utils/google-analytics/events/passwordReset';
import PasswordCreatedEvent from '../../utils/google-analytics/events/passwordCreated';

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [requestStatus, setRequestStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { id } = useParams()

  useEffect(() => {
    async function checkRequestStatus() {
      try {
        const url = process.env.REACT_APP_BACKEND_URL + "/users/resetIdCheck";
        const res = await axios.get(`${url}/${id}`)
        setLoading(false);
        setRequestStatus(res.data.status);
      } catch (error) { }
    }
    checkRequestStatus();
  }, [id])

  const submitHandler = (e) => {
    e.preventDefault();
    let reqBody = {};
    if (newUser()) {
      const email = getNewUserEmail();
      reqBody = { password, id, email };
    } else {
      reqBody = { password, id };
    }
    if (password === confirmPassword) {
      axios({
        url: process.env.REACT_APP_BACKEND_URL + "/users/reset",
        data: reqBody,
        method: "post",
      }).then(res => {
        if (res.data.message) {
          setErrorMessage(res.data.message);
          setError(true);
        } else {
          setPasswordReset(true);
          sendGA(newUser() ? PasswordCreatedEvent() : PasswordResetEvent());
          history.push("/login");
        }
      })
    } else {
      setErrorMessage("Your password inputs do not match.");
      setError(true);
    }
  }

  const newUser = () => {
    return new URL(document.URL).searchParams.get('new') === 'true';
  }

  function getNewUserEmail() {
    return new URL(document.URL.replaceAll("+", "%2B")).searchParams.get('email');
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url(${adascloginImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();
  let body;
  if (passwordReset) {
    body = (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <span>Your password has been reset</span>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    body = (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>

            <Typography component="h1" variant="h5">
              {newUser() ? 'Create Your Password' : 'Reset Your Password'}
            </Typography>
            {error && <ErrorNotice style={{ textAlign: "center" }} aria-label={"Request has already been sent, please check your email."} message={errorMessage} clearError={() => setError(false)}></ErrorNotice>}
            <form action="/reset/<%= token %>" method="POST">
              <div className="form-group">
                <label for="password">New Password</label>
                <input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="New password" autofocus="autofocus" className="form-control" />
              </div>
              <div className="form-group">
                <label for="confirm">Confirm Password</label>
                <input type="password" name="confirm" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm password" className="form-control" />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary" onClick={submitHandler}>Update Password</button>

              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
  if (loading) {
    return <p>Loading...</p>
  }
  if (!requestStatus || requestStatus === -1) {
    return (<ErrorPage status={requestStatus} />)
  } else {
    return (body);
  }
}