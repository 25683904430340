import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import urlActions from "../../store/actions/urlActions";
import Alert from '@material-ui/lab/Alert';
import ScanOptions from "../layout/ScanOptions";
import axios from "axios";
import { renderOption } from '../../utils/renderHelpers';  
import "./style.css"

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: "30px",
    minWidth: 270,
    borderColor: "#80bdff",
    fullWidth: false
  },
  mobileForm: {
    margin: "30px 10px",
    minWidth: 180,
  },
}));

export function DateSelect(props) {
  const [showSuccess, setShowSuccess] = useState(false);
  const matches = useMediaQuery("(max-width:480px)");
  const classes = useStyles();
  const { selectedUrl, times, selectedTime, selectedScanId } = useSelector(
    (state) => state.urls
  );
  const dispatch = useDispatch();

  const deleteSelectedDateHandler = useCallback(async () => {
    const DeleteScan = process.env.REACT_APP_BACKEND_URL + "/scans/DeleteScan";
    const authToken = localStorage.getItem("auth-token");
    if (!authToken) {
      throw new Error("No authentication token found");
    }
    await axios({
      method: "post",
      url: DeleteScan,
      data: { date: selectedTime, link: selectedUrl },
      headers: { "x-auth-token": authToken },
      responseType: "json",
    });
    setShowSuccess("Selected Date deleted successfully!");
    dispatch(urlActions.deleteScan(selectedUrl, selectedTime));
  }, [selectedTime, selectedUrl, dispatch]);

  return (
    <>
      <div className="mr-3">
        {showSuccess &&
          <Alert className="scan_complete_alert" onClose={() => setShowSuccess(false)}>{showSuccess}</Alert>
        }
        <FormControl
          style={{ width: "10rem" }}
          className={!matches ? `${classes.formControl} date_drop_down` : classes.mobileForm}
          disabled={props.mainLoading}
          >
          <InputLabel htmlFor="DateSelect" style={{ fontWeight: "bold", fontSize: "1.2rem", width: "21rem" }}>  Currently Selected Scan Date
          </InputLabel>
          <Select
            style={{ fontSize: "1.2rem" }}
            native
            id="DateSelect"
            value={selectedTime || "Loading Dates"}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.options.selectedIndex]
              const scanID = selectedOption.getAttribute("data-scanid")
              dispatch(urlActions.changeSelectedTime(e.target.value, scanID))
            }}
          >
            {!selectedTime && <option value="Loading Dates">Loading Dates...</option>}
            {times[selectedUrl] ? renderOption(times[selectedUrl]) : <option value="No Dates Found">No Dates Found</option>}
          </Select>
        </FormControl>
      </div>
      <ScanOptions
        onExportClick={props.exportErrors}
        onDeleteClick={deleteSelectedDateHandler}
        disabled={!selectedTime || (!times[selectedUrl] && Object.keys(times).length) || props.mainLoading}
        selectedUrl={selectedUrl}
        selectedScanId={selectedScanId}
        setShowSuccess={setShowSuccess}
      />
    </>
  );
}
