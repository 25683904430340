import ReactGA from 'react-ga4';

const PasswordResetEvent = () => {
    const action = 'password_reset_sent';
    const category = 'password';

    ReactGA.event(action, {
        category: category,
        action: action,
        page: window.location.pathname,
    });
}

export default PasswordResetEvent;