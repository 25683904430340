import {
    CHECK_LOGIN,
  CHECK_USER_AUTH,
  IS_AUTHENTICATED,
  IS_NOT_AUTHENTICATED,
  IS_ADMIN,
  IS_NOT_ADMIN,
  LOG_OUT,
} from "./types";

const userActions = {
  isAuthenticated: (payload) => ({ type: IS_AUTHENTICATED , payload:payload }),
  isNotAuthenticated: () => ({ type: IS_NOT_AUTHENTICATED}),
  isAdmin: () => ({ type: IS_ADMIN }),
  isNotAdmin: () => ({ type: IS_NOT_ADMIN}),
  checkAuth: () => ({ type: CHECK_USER_AUTH }),
  login: (email, password) => ({ type: CHECK_LOGIN, payload:{email, password} }),
  logOut: () => ({type:LOG_OUT})
};
export default userActions;
