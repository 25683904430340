import ReactGA from 'react-ga4';

const ForgotPasswordEvent = (email) => {
    const action = 'forgot_password_request_submitted';
    const category = 'forgot_password';

    ReactGA.event(action, {
        category: category,
        action: action,
        page: window.location.pathname,
        userEmail: email,
        User: email,
    });
}

export default ForgotPasswordEvent;