import errorTypeInfo from "./errorInfo.json"

const getErrorInfoData = () => {
    const modifiedErrorInfo = {};
    errorTypeInfo.forEach(errorTypeInfo =>{
        modifiedErrorInfo[errorTypeInfo.name] = errorTypeInfo;
      })
      return modifiedErrorInfo;
 }
 

 const modifiedErrorInfoList = getErrorInfoData();

 export default modifiedErrorInfoList;