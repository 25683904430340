import {
  ADD_NEW_SCAN,
  CHANGE_SELECTED_TIME,
  CHANGE_SELECTED_URL,
  GET_LINK_AND_CSS_SELECTORS,
  GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR,
  STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR,
  GET_SCORE_CARD_DATA,
  GET_URLS,
  STORE_LINK_AND_CSS_SELECTORS,
  STORE_SCORE_DATA,
  STORE_URLS,
  ADD_URL,
  STORE_URLS_DETAILS,
  DELETE_SCAN,
  SET_LOADING
} from "./types";

const urlActions = {
  storeUrls: (urls) => ({ type: STORE_URLS, payload: { urls } }),
  addUrl: (url) => ({ type: ADD_URL, payload: { url } }),
  getUrlsDetails: () => ({ type: GET_URLS }),
  storeUrlsDetails: (payload) => ({ type: STORE_URLS_DETAILS, payload }),
  getScoreCardData: (selectedUrl, selectedTime) => ({ type: GET_SCORE_CARD_DATA, payload: { mainUrl: selectedUrl, time: selectedTime } }),
  setScoreCardData: (payload) => ({ type: STORE_SCORE_DATA, payload }),
  getLinksAndCssSelectors: (selectedUrl, selectedTime) => ({ type: GET_LINK_AND_CSS_SELECTORS, payload: { mainUrl: selectedUrl, time: selectedTime } }),
  getLinksAndCssSelectorsForNewError: (data) => ({ type: GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR, payload: data }),
  storeLinksAndCssSelectors: (payload) => ({ type: STORE_LINK_AND_CSS_SELECTORS, payload }),
  storeLinksAndCssSelectorsNewError: (payload) => ({ type: STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR, payload }),
  changeSelectedUrl: (url, data) => ({ type: CHANGE_SELECTED_URL, payload: { url, data } }),
  changeSelectedTime: (time, scanId) => ({ type: CHANGE_SELECTED_TIME, payload: { time, scanId } }),
  addNewScan: (url, pageTitle, time, scanId) => ({ type: ADD_NEW_SCAN, payload: { url, pageTitle, time, scanId } }),
  deleteScan: (url, time) => ({ type: DELETE_SCAN, payload: { url, time } }),
  setLoading: () => ({ type: SET_LOADING }),
};
export default urlActions;
