import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../../context/UserContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios"
import { Progress } from '@chakra-ui/react'
import SimpleModal from "../pages/Dashcomponents/SimpleModal";
import urlActions from "../../store/actions/urlActions";
import "./style.css"
import { createTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import WebsiteScanEvent from "../../utils/google-analytics/events/websiteScan";
import { reportWebVitals as sendGA } from "../../reportWebVitals";
import StyledMenu from "../helper/StyledMenu";
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import userActions from "../../store/actions/userActions";

const useStyles = makeStyles(() => ({
  button: {
    marginRight: 15,
    marginLeft: 15,
    height: "100%"
  }
}))

const previousRecordsCache = {};
const previousFullSiteRecordsCache = {};

const IndividualScan = (mainLoading) => {
  const [scanComplete, setScanComplete] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch()
  const { selectedUrl, errors } = useSelector(state => state.urls);
  const [url, setUrl] = useState(selectedUrl);
  const urls = useSelector(state => state.urls.urls);
  const [error, setError] = useState(false);
  const [errorUrl, setErrorUrl] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [showScanErrror, setShowScanErrror] = useState(false);
  const { userData } = useContext(UserContext);
  const [ scanSuccessMessage, setScanSuccessMessage ] = useState('Your scan was ran successfully!');
  const [ isPreviousSiteRecords, setIsPreviousSiteRecords ] = useState(true);
  const [ previousRecord, setPreviousRecord ] = useState([]);
  const [ previousFullSiteRecord, setPreviousFullSiteRecord ] = useState([]);
  const [differences, setDifferences] = useState([]);
  const fetchedFullSiteRecords = useRef(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const formatErrorData = (record) => {
    const formattedErrors = {};
    if (record && record.errorList) {
      for (const errorType in record.errorList) {
        const error = record.errorList[errorType];
        formattedErrors[errorType] = {
          id: errorType,
          description: error.description,
          xpaths: error.xpaths || [],
          selectors: error.selectors || [],
          text: error.text || [],
          hidden: error.hidden || [],
          count: error.count || 0,
          category: error.category,
        };
      }
    }
    return formattedErrors;
  };

  const findNewErrorData = (prevError, currentError) => {
    const newErrorData = {
      id: currentError.id,
      description: currentError.description,
      xpaths: [],
      selectors: [],
      text: [],
      hidden: [],
      category: currentError.category,
      count: 0
    };

    currentError.xpaths.forEach((xpath, index) => {
      if (!prevError.xpaths.includes(xpath)) {
        newErrorData.xpaths.push(xpath);
        newErrorData.selectors.push(currentError.selectors[index]);
        newErrorData.text.push(currentError.text[index]);
        newErrorData.hidden.push(currentError.hidden[index]);
        newErrorData.count += 1;
      }
    });

    return newErrorData;
  };

  const compareErrorData = (prevErrors, currentErrors) => {
    const differences = {};
    for (const errorType in currentErrors) {
      if (prevErrors[errorType]) {
        const newError = findNewErrorData(prevErrors[errorType], currentErrors[errorType]);
        if (newError.count > 0) {
          differences[errorType] = newError;
        }
      } else if (!prevErrors[errorType] && currentErrors[errorType].xpaths.length > 0) {
        differences[errorType] = currentErrors[errorType];
      }
    }
    return differences;
  };

  const compareScanRecords = (previousRecords, currentRecords) => {
    const differences = [];
    
    previousRecords.forEach(previousRecord => {
      const matchingPreviousRecord = currentRecords.find(currentRecord => currentRecord.name === previousRecord.name);
      const currentErrorData = matchingPreviousRecord ? formatErrorData(matchingPreviousRecord) : {};
      const previousErrorData = formatErrorData(previousRecord);

      const errorDifferences = compareErrorData(previousErrorData, currentErrorData);
      
      if (Object.keys(errorDifferences).length > 0) {
        differences.push({
          ...previousRecord,
          newErrorCount: Object.keys(errorDifferences) ? parseInt(Object.keys(errorDifferences).length) : 0,
          errorList: errorDifferences,
        });
      }
    });

    return differences;
  };

  useEffect(() => {
    if (previousRecord.length > 0 && previousRecord[0] !== null && errors != null && errors.length > 0) {
      const differences = compareScanRecords(previousRecord, errors);
      setDifferences(differences);
      dispatch(urlActions.getLinksAndCssSelectorsForNewError(differences));
    }

    if (previousFullSiteRecord.length > 0 && errors != null && errors.length > 0) {
      const differences = compareScanRecords(previousFullSiteRecord, errors);    
      setDifferences(differences);
      dispatch(urlActions.getLinksAndCssSelectorsForNewError(differences));
    }

  }, [errors, previousRecord, previousFullSiteRecord]);

  useEffect(() => {
    async function fetchData() {

      if (selectedUrl && errors != null && errors.length > 1) {
        if (!previousFullSiteRecordsCache[selectedUrl] && !fetchedFullSiteRecords.current) {
          fetchedFullSiteRecords.current = true;
          await getPreviousFullSiteRecords(selectedUrl);
        } else if (previousFullSiteRecordsCache[selectedUrl]) {
          setPreviousFullSiteRecord(previousFullSiteRecordsCache[selectedUrl]);
        }
      } else {
        if (selectedUrl && !previousRecordsCache[selectedUrl] && isPreviousSiteRecords) {
          setIsPreviousSiteRecords(false);
          await getPreviouScanUrl(selectedUrl);
        } else if (previousRecordsCache[selectedUrl]) {
          setPreviousRecord(previousRecordsCache[selectedUrl]);
        }
      }
    }
    fetchData().catch(error => console.error('Error in fetchData:', error));

  }, [errors, previousRecord.length, previousFullSiteRecord.length, isPreviousSiteRecords]);
  
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScanCurrentUrl = (e) => {
    setPreviousRecord([]);
    setIsPreviousSiteRecords(true);
    getIndividualScan(e);
    setAnchorEl(null);
  };

  const handleScanFullWebsite = (client_visible) => {
    setPreviousRecord([]);
    getFullWebsiteScan(client_visible);
    setAnchorEl(null);
  };

  const getPreviouScanUrl = async () => {

    let urlObj = [];
    try {
      urlObj = new URL(url);
    } catch (e) {
      setErrorUrl(true)
      return
    }

    let targetUrl = url;
    if (targetUrl === urlObj.origin) {
      targetUrl = url + "/";
    }

    const isValid = urls.some(userUrl => targetUrl.indexOf(userUrl) === 0)

    if (isValid || userData.user?.isAdmin) {
      await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/previous/url", {
        url: targetUrl,
      }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
        setPreviousRecord(response.data);
        previousRecordsCache[targetUrl] = response.data;
        return response.data
      }).catch(function (error) {
        if (error.response.status === 401) {
          dispatch(userActions.logOut())
        }
      });
    } else {
      setError(true);
    }
  }

  const getPreviousFullSiteRecords = async () => {

    let urlObj = [];
    try {
      urlObj = new URL(url);
    } catch (e) {
      setErrorUrl(true)
      return
    }

    let targetUrl = url;
    if (targetUrl === urlObj.origin) {
      targetUrl = url + "/";
    }

    const isValid = urls.some(userUrl => targetUrl.indexOf(userUrl) === 0)

    if (isValid || userData.user?.isAdmin) {
      await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/previous/fullsite/records", {
        url: targetUrl,
      }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
        setPreviousFullSiteRecord(response.data);
        previousFullSiteRecordsCache[targetUrl] = response.data;
        return response.data;
      }).catch(function (error) {
        if (error.response.status === 401) {
          dispatch(userActions.logOut())
        }
      });
    } else {
      setError(true);
    }
  }

  const getFullWebsiteScan = async (client_visible) => {
    setScanSuccessMessage("Full-site scan is in progress & is running in the background. It may take 5-15 minutes to complete, depending on the website content and size.");
    setScanComplete(false);

    let urlObj = [];
    try {
      urlObj = new URL(url);
    } catch (e) {
      setErrorUrl(true)
      return
    }

    let targetUrl = url;
    if (targetUrl === urlObj.origin) {
      targetUrl = url + "/";
    }

    const mainUrl = urlObj.origin + "/";
    const isValid = urls.some(userUrl => targetUrl.indexOf(userUrl) === 0)

    if (isValid && mainUrl !== selectedUrl) {
      dispatch(urlActions.changeSelectedUrl(mainUrl));
    }
    if (!isValid && !userData.user?.isAdmin) {
      setUrl(selectedUrl)
    }

    if (isValid || userData.user?.isAdmin) {
      setIsScanning(true)
      setShowScanErrror(false);
      const currentTime = moment().format("MM/DD/YYYY, h:mm:ss a");
      await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/scanfullwebsite", {
        url: mainUrl,
        targetUrl: targetUrl,
        client_visible: client_visible,
        currentTime,
        numberOfUrls: 1,
      }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
        setShowScanErrror(false);
        setIsScanning(false);
        setScanComplete(true);
        sendGA(WebsiteScanEvent(true, userData.user)); //Google Analytics - scan completed
        return response.data
      }).catch(function (error) {
        if (error.response.status === 401) {
          dispatch(userActions.logOut())
        }
        setShowScanErrror(true);
        setIsScanning(false);
        setScanComplete(false);
        sendGA(WebsiteScanEvent(false, userData.user)); //Google Analytics - scan failed
        return { "pageTitle": "" }
      });

      if (!showScanErrror && !isScanning) {
        if (userData.user?.isAdmin && !isValid) {
          const addUrlToUser = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/users/addUrlToUser", {
            url: mainUrl,
            userId: userData.user.id,
          }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).catch(function (error) {
            if (error.response.status === 401) {
              dispatch(userActions.logOut())
            }
          });

          await addUrlToUser;
          dispatch(urlActions.addUrl(mainUrl));
        }
      }
    } else {
      setError(true);
    }
  }

  const getIndividualScan = async (e) => {
    setScanSuccessMessage('Your scan was ran successfully!');
    setScanComplete(false);
    e.preventDefault();

    let urlObj = [];
    try {
      urlObj = new URL(url);
    } catch (e) {
      setErrorUrl(true)
      return
    }

    let targetUrl = url;
    if (targetUrl === urlObj.origin) {
      targetUrl = url + "/";
    }

    const mainUrl = urlObj.origin + "/";
    const isValid = urls.some(userUrl => targetUrl.indexOf(userUrl) === 0)

    if (isValid && mainUrl !== selectedUrl) {
      dispatch(urlActions.changeSelectedUrl(mainUrl));
    }
    if (!isValid && !userData.user?.isAdmin) {
      setUrl(selectedUrl)
    }

    if (isValid || userData.user?.isAdmin) {
      setIsScanning(true)
      setShowScanErrror(false);
      const currentTime = moment().format("MM/DD/YYYY, h:mm:ss a");
      const scanResponse = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/scanTargetUrl", {
        url: mainUrl,
        targetUrl: targetUrl,
        currentTime,
        numberOfUrls: 1,
      }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
        setShowScanErrror(false);
        setIsScanning(false);
        setScanComplete(true);
        sendGA(WebsiteScanEvent(true, userData.user)); //Google Analytics - scan completed
        return response.data
      }).catch(function (error) {
        if (error.response.status === 401) {
          dispatch(userActions.logOut())
        }
        setShowScanErrror(true);
        setIsScanning(false);
        setScanComplete(false);
        sendGA(WebsiteScanEvent(false, userData.user)); //Google Analytics - scan failed
        return { "pageTitle": "" }
      });

      if (!showScanErrror && !isScanning) {
        if (userData.user?.isAdmin && !isValid) {
          const addUrlToUser = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/users/addUrlToUser", {
            url: mainUrl,
            userId: userData.user.id,
          }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).catch(function (error) {
            if (error.response.status === 401) {
              dispatch(userActions.logOut())
            }
          });

          await addUrlToUser;
          //await Axios.get(process.env.REACT_APP_BACKEND_URL + "/scans/getUrls")
          dispatch(urlActions.addUrl(mainUrl));
          //dispatch(urlActions.getUrlsDetails());
        }

        dispatch(urlActions.addNewScan(mainUrl, scanResponse.pageTitle, currentTime, scanResponse.scanId));
      }
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if (selectedUrl) {
      setUrl(selectedUrl);
    }
  }, [selectedUrl])

  const asteriskAcessibility = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          sx: {
            ariaHidden: false
          }
        },
      }
    }
  })

  const validateUrl = (e) => {
    let pattern = new RegExp(/(^https:\/\/)|(^http:\/\/)/);
    let url = e.target.value
    if (!pattern.test(e.target.value)) {
      url = "https://" + url;
      setUrl(url)
    }
  }

  return (
    <div>
      {showScanErrror ? (
        <Alert
          className="scan_complete_alert"
          severity="error"
          onClose={() => setShowScanErrror(false)}
        >
          Something went wrong with your scan.
          <Button color="inherit" size="small" variant="outlined" onClick={getIndividualScan}>
            retry your scan
          </Button>
        </Alert>
      ) : null}
      {scanComplete && !showScanErrror ? (
        <Alert className="scan_complete_alert max-width-30" onClose={() => setScanComplete(false)}>{scanSuccessMessage}</Alert>
      ) : null}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          InputLabelProps={asteriskAcessibility}
          className="individual_scan_input"
          aria-label="Paste a url from your site"
          variant="outlined"
          required
          id="url"
          label="Paste a url from your site"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onBlur={(e) => validateUrl(e)}
          name="url"
          autoFocus
          error={error}
          disabled={mainLoading.mainLoading}
        />
        {userData.user?.isAdmin ?
          <div className="primary-menu mx-3">
            <Button
              id="menu-options"
              aria-controls={open ? 'menu-options' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              disabled={mainLoading.mainLoading}
            >
              Scan Url
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleScanCurrentUrl}>Scan Current Url</MenuItem>
              <MenuItem onClick={() => handleScanFullWebsite(false) }>Run Admin-Only Full-Site Scan</MenuItem>
              <MenuItem onClick={() => handleScanFullWebsite(true) }>Run Client-Visible Full-Site Scan</MenuItem>
            </StyledMenu>
          </div>
          :
          <Button
            style={{ backgroundColor: "#0063ce", color: "#fff", padding: "1rem" }}
            type="submit"
            value="Individual Scan Button"
            className={classes.button}
            onClick={getIndividualScan}
            disabled={mainLoading.mainLoading}
          >
            Scan Url
          </Button>
        }
      </div>
      <div align="center" font-size="large" role="status" aria-live="polite">
        {isScanning ? (
          <div >
            <Progress size='xs' isIndeterminate></Progress>
          </div>
          // ) : <Button onClick={() => history.push("/errorBreakdown")} style={{alignItems:"center",marginTop: "30px", marginLeft: "40%",height: "50px"}}>View Latest IndividualScan Scan Results</Button>}
        ) : null}
      </div>
      <SimpleModal open={error} handleClose={() => { setError(false) }}>You do not have access to scan this URL. Please type the url exactly as displayed in the url selection field.</SimpleModal>
      <SimpleModal open={errorUrl} handleClose={() => { setErrorUrl(false) }}>Invalid Url. Please input valid url</SimpleModal>
    </div>
  );
};

export default IndividualScan;
