import React, { useState } from "react";
import Logo from "../../assets/logo.svg";
import Dashboard from "../../assets/nav/dashboard.svg";
import Audit from "../../assets/nav/audit.svg";
import Messages from "../../assets/nav/messages.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "./style.css";

const navOptions = [
  {
    icon: Dashboard,
    label: "Dashboard",
    route: "/",
    clickable: true,
    isAdmin: false
  },
  {
    icon: Audit,
    label: "Register",
    route: "/register",
    clickable: true,
    isAdmin: true
  },
  {
    icon: Audit,
    label: "Users",
    route: "/user",
    clickable: true,
    isAdmin: true
  },
  {
    icon: Audit,
    label: "Scheduled Scans",
    route: "/scheduled-scans",
    clickable: true,
    isAdmin: true
  },
  {
    icon: Messages,
    label: "Support Center",
    route: "/support",
    clickable: true,
    isAdmin: false
  },
];

const Nav = () => {
  const [selectedRoute, setSelectedRoute] = useState(window.location.pathname);

  const { user } = useSelector(
    (state) => state.auth
  );

  return (
    <div role="list" className="nav">
      <div className="nav__logo">
        <img src={Logo} alt="ADA SITE COMPLIANCE"></img>
      </div>
      {navOptions.map((option) => {
        if (option.isAdmin === false || option.isAdmin === user.isAdmin) {
          return (<Link role="listItem" className="nav__link" to={option.route} key={option.label}>
            <div
              className={`nav__option-container ${selectedRoute === option.route && "nav__option-container--selected"}`}
              onClick={() => {
                if (option.clickable) {
                  setSelectedRoute(option.route)
                }
              }}
            >
              <img src={option.icon} alt="" />
              <p className={`nav__option-label ${selectedRoute === option.route && "nav__option-label--selected"}`}>
                {option.label}
              </p>
            </div>
          </Link>)
        }
        return null;
      })}



    </div>
  );
};

export default Nav;
