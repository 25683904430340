import React, { useState } from 'react';
import axios from "axios";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import adascloginImage from './loginDashboard.png'
import ErrorNotice from "../misc/ErrorNotice";
import ForgotPasswordEvent from "../../utils/google-analytics/events/forgotPassword";
import {reportWebVitals as sendGA} from "../../reportWebVitals";

export default function ForgotPassword() {
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${adascloginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

  const classes = useStyles();

  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [requestAlreadySent, setRequestAlreadySent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const submitHandler = (e) => {
      e.preventDefault();
      const body = {
          email,
      };

      axios({
          url: process.env.REACT_APP_BACKEND_URL + "/users/forgot",
          data: body,
          method: "post",
      }).then(res => {

        if(res.data.message){
          setRequestAlreadySent(true);
          setErrorMessage(res.data.message);
        }else{
          setEmailSent(true);
          sendGA(ForgotPasswordEvent());
        }
      })
  }

  let body;
  if (emailSent) {
      body = (
        <Grid container component="main" className={classes.root}>
        <title>Forgot Password  - ADAash</title>

        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <span>An email with reset instructions is on its way</span>
          </div>
        </Grid>
      </Grid>
      );
  } else {

    body = (
        <Grid container component="main" className={classes.root}>
        <title>Forgot Password  - ADAash</title>

    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>

        <Typography component="h1" variant="h5">
        Forget Your Password?
        </Typography>
        <form className={classes.form} action="/forgot" method="POST">
        <legend style={{textAlign: "center"}}>Enter Your Accounts Associated Email.</legend>
              <TextField  variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              id="forgot-password-email"
              type="email"
            onChange={(e) => setEmail(e.target.value)}
              ></TextField>
        <div style={{textAlign: "center"}} className="form-group">
        <button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="btn btn-primary"
        disabled={emailSent}
        value="Reset Password" onClick={submitHandler}>Reset Password</button>
        </div>
        {requestAlreadySent && <ErrorNotice style={{textAlign: "center"}} aria-label={"Request has already been sent, please check your email."} message={errorMessage} clearError={() => setRequestAlreadySent(false)}></ErrorNotice>}
      </form>
      </div>
    </Grid>
  </Grid>
    );
  }
  return (
   body
  );

};