import ReactGA from 'react-ga4';

const SupportRequestEvent = (user) => {
    const action = 'support_request_submitted';
    const category = 'support_requests';

    ReactGA.event(action, {
        category: category,
        action: action,
        page: window.location.pathname,
        userDisplayName: user.displayName,
        userEmail: user.email,
        User: user.email,
        userUrls: user.urls,
    });
}

export default SupportRequestEvent;