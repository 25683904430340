import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import adascloginImage from './loginDashboard.png'
import { useSelector } from "react-redux";
import SupportRequestEvent from "../../utils/google-analytics/events/supportRequest";
import {reportWebVitals as sendGA} from "../../reportWebVitals";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${adascloginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function SupportTicket() {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState();
  const { user } = useSelector(
    (state) => state.auth
  );
  const classes = useStyles();
  const history = useHistory();
  const submit = async (e) => {
    e.preventDefault();
    const regInfo = {
      subject: subject,
      message: message,
      user
    }

    try {
      Axios.post(process.env.REACT_APP_BACKEND_URL + '/users/sendSupportTicket?', regInfo)
        .then(res => {
          history.push("/")
        })
        sendGA(SupportRequestEvent(user.email));
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://adasitecompliance.com/">
          AdaSiteCompliance.com
        </Link> {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <title>Support - ADAash</title>
      <CssBaseline />
      <Grid item xs={15} sm={5} md={4} className={classes.image} ><p className="sr-only" >ADA Site Compliance - ADAsh</p></Grid>
      <Grid item xs={12} sm={7} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          <Typography component="h1" variant="h5">
            Support Request
          </Typography>
          {error && (
            <ErrorNotice aria-label={error} message={error} clearError={() => setError(undefined)} />
          )}
          <form className={classes.form} onSubmit={submit}>
            <TextField
              fullWidth
              margin="normal"
              required
              style={{ borderColor: "#959595" }}
              variant="outlined"
              id='subject'
              name='subject'
              label='Subject'
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              fullWidth
              multiline
              minRows="15"
              margin="normal"
              required
              style={{ borderColor: "#959595" }}
              variant="outlined"
              id='message'
              name='message'
              label='Message'
              onChange={(e) => setMessage(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              style={{ borderColor: "#959595" }}
              variant="outlined"
              margin="normal"
              color="primary"
              value="Submit Message"
              className={classes.submit}
              aria-hidden="true"
              aria-disabled="false"
              onClick={submit}
            >
              Submit Support Request
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
