import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
} from "@chakra-ui/react";
import Nav from "../../components/Nav";
import SearchBar from "../../components/SearchBar";
import GeneralMetrics from "../../components/GeneralMetrics";
import ScanResult from "../../components/ScanResult";
import UrlDetail from "../../components/UrlDetail";
import { Progress } from '@chakra-ui/react'
import "../css/home.css";
import { useDispatch, useSelector } from "react-redux";
import urlActions from "../../store/actions/urlActions";

export default function DashboardMain(props) {
  const { selectedUrl, selectedTime, scoreCardsData , errors, loading, new_errors} = useSelector(
    (state) => state.urls
  );
  
  const dispatch = useDispatch();
  const [scanComplete, setScanComplete] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState("total_errors");

  useEffect(() => {
    if (selectedUrl && selectedTime) {
      dispatch(urlActions.getLinksAndCssSelectorsForNewError([]));
    }
  }, [selectedUrl, selectedTime, dispatch]);

  let element = document.createElement('span');
  element.style.position = 'absolute';
  element.style.left = '-99999px';
  for(let i = 0; i< document.getElementsByClassName("MuiButtonBase-root").length; i++){
    document.getElementsByClassName("MuiButtonBase-root")[i].appendChild(element);
  }
  for(let i = 0; i< document.getElementsByClassName("MuiIconButton-root").length; i++){
    document.getElementsByClassName("MuiIconButton-root")[i].appendChild(element);
  }

return (
<div className="dashboard">
<ChakraProvider>
<Nav/>
  <Box className="App" minHeight="100vh">

    <div className="content" style={{}}>
      <SearchBar mainLoading={loading}/>
      {loading&&<Progress size='xs' isIndeterminate />}
      <GeneralMetrics complianceData={scoreCardsData || {}} />
      <ScanResult selectedBadge={selectedBadge} setSelectedBadge={setSelectedBadge}  totalPage={errors}/>
      { selectedBadge === "new_errors" ?       
        new_errors && new_errors.length > 0 ? (
          new_errors.map(url => <UrlDetail
            url={url}
            key={url._id}
            scanComplete={scanComplete}
            setScanComplete={setScanComplete}
            selectedBadge={selectedBadge}
            count={Object.keys(url.errorList).length}/> )
        ) : (<h3>No Scans Found</h3>)
      :
        errors && errors.length > 0 ? (
          errors.map(url => <UrlDetail
            url={url}
            key={url._id}
            scanComplete={scanComplete}
            setScanComplete={setScanComplete}
            selectedBadge={selectedBadge}
            count={Object.keys(url.errorList).length}/> )
        ) : (<h3>No Scans Found</h3>)
      }
      
    </div>
  </Box>
</ChakraProvider>
</div>
)
}
