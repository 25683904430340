import { call, put, select, takeLatest } from "redux-saga/effects";
import { urlService } from "../../services/urls";
import { GET_LINK_AND_CSS_SELECTORS, GET_SCORE_CARD_DATA, GET_URLS, GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR } from "../actions/types";
import urlActions from "../actions/urlActions";
import userActions from "../actions/userActions";

function* getUrlDetails() {
  console.log("getUrlDetails");
  try {
    const state = yield select(state => state.urls);
    const urls = yield call(urlService.getUrls, { selectedUrl: state.selectedUrl });
    let payload = {
      times: {},
    };

    let dateList = [];
    if(urls){
      urls.map(url => {
        dateList.push(url.data);
      });
      payload.times[state.selectedUrl] = dateList;
      yield put(urlActions.storeUrlsDetails(payload));
    } else {
      yield put(urlActions.storeUrlsDetails({'times': {}}));
    }
  } catch (err) {
    yield put(userActions.logOut());
  }
}

function* getScoreCardData({ payload }, state) {
  const { mainUrl, time } = payload;
  console.log("getScoreCardData payload: ", payload);
  try {
    const state = yield select(state => state.urls);
    const cachedData = state.cache[mainUrl]?.[time];
    
    if (cachedData) {
      yield put(urlActions.setScoreCardData(cachedData));
    } else {
      const scoreCardData = yield call(urlService.getScoreCardsData, { mainUrl, time });

      let firstComplianceScore = {};
      if (state.times[mainUrl] !== undefined) {
        const firstTime = state.times[mainUrl][0];
        firstComplianceScore = yield call(urlService.getScoreCardsData, { mainUrl, time: firstTime });
      }
      
      const newData = {
        ...scoreCardData,
        firstComplianceScore,
        mainUrl,
        time
      };

      yield put(urlActions.setScoreCardData(newData));
    }
  } catch (err) {
    yield put(userActions.logOut());
  }
}

function* getLinksAndCssSelectors({ payload }) {
  console.log("doot1")
  const { mainUrl, time } = payload;
  console.log("getLinksAndCssSelectors payload: ", payload);
  const cachedData = yield select(state => state.urls.cacheLink[mainUrl]?.[time]);
  console.log("doot2");
  if (cachedData) {
    yield put(urlActions.storeLinksAndCssSelectors({ mainUrl, time, data: cachedData }));
  } else {
    try {
      const listOfSelectors = yield call(urlService.getLinksAndCssSelectorsData, { mainUrl, time });
      yield put(urlActions.storeLinksAndCssSelectors({ mainUrl, time, data: listOfSelectors }));
    } catch (err) {
      yield put(userActions.logOut());
    }
  }
}

function* getLinksAndCssSelectorsForNewError({ payload } ) {  
  try {
    const listOfSelectors = yield call(urlService.getLinksAndCssSelectorsForNewErrorData, payload);    
    yield put(urlActions.storeLinksAndCssSelectorsNewError(listOfSelectors));
  } catch (err) {
    yield put(userActions.logOut());
  }
}

function* watchUrlSaga() {
  console.log("watchUrlSaga");
  yield takeLatest(GET_URLS, getUrlDetails);
  yield takeLatest(GET_SCORE_CARD_DATA, getScoreCardData);
  yield takeLatest(GET_LINK_AND_CSS_SELECTORS, getLinksAndCssSelectors);
  yield takeLatest(GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR, getLinksAndCssSelectorsForNewError);
}
export default watchUrlSaga;
