import {call, put, takeLatest} from 'redux-saga/effects'
import {auth} from '../../services/auth'
import { CHECK_LOGIN, CHECK_USER_AUTH } from '../actions/types';
import urlActions from '../actions/urlActions';
import userActions from '../actions/userActions';
function* checkUserAuth ({payload}) {
    try {
        const data = yield call(auth.checkAuth);
        if(!data.token) {
            throw Error("User not Authenticated");
        }
        yield put(userActions.isAuthenticated(data.user))
        yield put(urlActions.storeUrls(data.user.urls));
        yield put(urlActions.getUrlsDetails())
    } catch (err) {
        yield put(userActions.isNotAuthenticated());

    }
}

function* checkLoggedIn ({payload}) {
    try {
        const data = yield call(auth.getLoggedIn, payload);
        localStorage.setItem("auth-token", data.token)
        yield put(userActions.isAuthenticated(data.user))
        yield put(urlActions.storeUrls(data.user.urls));
        yield put(urlActions.getUrlsDetails())
    }
    catch (err) {
        yield put(userActions.isNotAuthenticated());
    }
}


function* watchUserAuthSaga () {
    yield takeLatest(CHECK_USER_AUTH,checkUserAuth )
    yield takeLatest(CHECK_LOGIN,checkLoggedIn )
}
export default watchUserAuthSaga;