import React from 'react'

const ErrorPage = (props) => {
    return (
        <div className='bg-dark'>
            {props.status === -1 ? (
                <h1 className="text-center p-4 bg-danger">This link has expired, please try again</h1>
            ) : (
                <h1 className="text-center p-4 bg-danger">Page does not exist</h1>
            )}
        </div>
    )
}

export default ErrorPage
