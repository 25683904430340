import Axios from 'axios'
export const auth = {
    checkAuth: async () =>  {
        try {

            let token = localStorage.getItem("auth-token");
            if(!token) {
                throw Error("User not authenticated");
            }
            const {data} = await Axios.post(
                process.env.REACT_APP_BACKEND_URL + "/users/tokenIsValid",
                { headers: { 'Authorization': token  } }
            );
            return  {
                token: data.token,
                user: data.user
            }
        }
        catch (err) {
            throw Error("User not authenticated");
        }
    },
    getLoggedIn : async ({email, password}) => {
        try {
            const result= await Axios.post(
                process.env.REACT_APP_BACKEND_URL + "/users/login",
                {email, password}
            );
            return  {
                token: result.data.token,
                user: result.data.user
            }
        } catch (error) { }
    }
}
